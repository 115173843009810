import axios from 'axios';
import { omitBy } from 'lodash';
import React, { createContext, useReducer } from 'react';
import { useMedusa } from '../hooks/use-medusa';
import { searchProducts } from '../services/meilisearchService';
import { Product } from '../types/Product';

export type ListProductOptions = {
  collectionIds?: string[];
  hitsPerPage?: number;
  keyword?: string;
  order?: 'title' | 'created_at' | 'price';
  direction?: 'asc' | 'desc';
  page?: number;
};

const defaultProductContext = {
  products: undefined,
  product: undefined,
  actions: {
    listProduct: async () => [],
    getProduct: async () => undefined,
  },
};

const ProductContext = createContext<{
  products:
    | {
        data: Product[];
        filterOptions: ListProductOptions;
        count: number;
        loading: boolean;
      }
    | undefined;
  actions: {
    listProduct: (options?: ListProductOptions) => Promise<Product[]>;
    listLatestProduct: (options?: ListProductOptions) => Promise<Product[]>;
    getProduct: (handle: string) => Promise<Product | undefined>;
  };
}>(defaultProductContext);

export default ProductContext;

const ACTIONS = {
  LIST_PRODUCT: 'LIST_PRODUCT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LIST_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    default:
      break;
  }
};

export const ProductProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultProductContext);
  const client = useMedusa();

  const listProduct = async (options: ListProductOptions = {}) => {
    dispatch({
      type: ACTIONS.LIST_PRODUCT,
      payload: {
        loading: true,
        filterOptions: options,
      },
    });
    const collectionsIds = options.collectionIds?.map(
      (id) => `collection_id = ${id}`
    );
    try {
      const payload = {
        hitsPerPage: options.hitsPerPage || 24,
        page: options.page ?? 1,
        filter: [collectionsIds],
        sort: options?.direction
          ? [`${options.order ?? 'title'}:${options.direction}`]
          : [],
        q: options.keyword,
      };
      const response = await searchProducts(
        omitBy(payload, (v) => Array.isArray(v) && v.length == 0)
      );
      dispatch({
        type: ACTIONS.LIST_PRODUCT,
        payload: {
          count: response.data.totalHits,
          filterOptions: options,
          data: response.data.hits,
          loading: false,
        },
      });
      return response.data?.hits;
    } catch (e) {
      // TODO: handle error
      dispatch({
        type: ACTIONS.LIST_PRODUCT,
        payload: {
          loading: false,
          filterOptions: options,
        },
      });
      return [];
    }
  };

  const getProduct = async (handle: string) => {
    const response = await client.products.list({
      handle,
    });
    return response.products?.[0];
  };

  const listLatestProduct = async (options: ListProductOptions = {}) => {
    const response = await axios.get('/store/products/latest', {
      params: options,
    });
    return response.data.products;
  };

  return (
    <ProductContext.Provider
      {...props}
      value={{
        ...state,
        actions: {
          listLatestProduct,
          listProduct,
          getProduct,
        },
      }}
    />
  );
};
