import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useLocation } from '@reach/router';
import { useMedusa } from '../hooks/use-medusa';
import { navigate } from 'gatsby';

const defaultCustomerContext = {
  customer: undefined,
};

const CustomerContext = createContext(defaultCustomerContext);
export default CustomerContext;

const ACTIONS = {
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  CLEAR_CUSTOMER: 'CLEAR_CUSTOMER',
  UPDATE_ORDERS: 'UPDATE_ORDERS',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case ACTIONS.CLEAR_CUSTOMER:
      return {
        ...state,
        customer: undefined,
      };
    default:
      break;
  }
};

export const CustomerProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultCustomerContext);
  const [loading, setLoading] = useState(true);
  const [initCustomer, setInitCustomer] = useState(false);
  const [isAccessChecked, setIsAccessChecked] = useState(false);
  const client = useMedusa();
  const location = useLocation();
  const updateCustomer = (customer) => {
    dispatch({ type: ACTIONS.UPDATE_CUSTOMER, payload: customer });
  };

  const clearCustomer = useCallback(() => {
    localStorage.removeItem('medusa_region');
    localStorage.removeItem('medusa_country');
    dispatch({ type: ACTIONS.CLEAR_CUSTOMER });
  }, []);

  const createCustomer = async (payload) => {
    const response = { customer: undefined, error: undefined };
    response.customer = await client.customers
      .create(payload)
      .then(({ customer }) => customer)
      .catch((err) => {
        console.log(err);
        response.error = err.response.data;
      });

    if (!response.error) {
      updateCustomer(response.customer);
    }

    return response;
  };

  const loginCustomer = async (payload) => {
    const response = { customer: undefined, error: undefined };

    response.customer = await client.auth
      .authenticate(payload)
      .then(({ customer }) => customer)
      .catch((err) => {
        response.error = err.response.data;
      });

    if (!response.error) {
      updateCustomer(response.customer);
    }

    return response;
  };

  const logoutCustomer = async () => {
    clearCustomer();
    await client.auth
      .deleteSession()
      .catch((error) => console.log('Unable to log out due to', error));
  };

  const updateCustomerDetails = async (payload) => {
    const response = { customer: undefined, error: undefined };
    response.customer = await client.customers
      .update(payload)
      .then(({ customer }) => customer)
      .catch((err) => {
        response.error = err.response.data;
      });

    if (!response.error) {
      updateCustomer(response.customer);
    }

    return response;
  };

  const requestResetPassword = async (email) => {
    await client.customers.generatePasswordToken({
      email: email || state?.customer?.email,
    });
  };

  const resetPassword = async (email, password, token) => {
    await client.customers.resetPassword({
      email,
      password,
      token,
    });
  };

  const me = useCallback(async () => {
    const customer = await client.customers
      .retrieve()
      .then(({ customer }) => customer)
      .catch((_) => {});

    if (customer) {
      updateCustomer(customer);
      setInitCustomer(true);
      setLoading(false);
      return true;
    }
    clearCustomer();
    setInitCustomer(true);
    setLoading(false);
    return false;
  }, [client.customers, clearCustomer]);

  const retrieveOrders = async () => {
    const orders = await client.customers
      .listOrders()
      .then(({ orders }) => orders)
      .catch((_) => []);

    return orders;
  };

  const getCompany = async () => {
    const res = await axios.get('/store/account/company');
    return res.data;
  };

  useEffect(() => {
    me();
  }, [me]);

  useEffect(() => {
    if (initCustomer) {
      const checkAccess = async () => {
        setIsAccessChecked(false);
        const path = location.pathname;
        const pathWithoutEndingSlash = path.endsWith('/')
          ? path.slice(0, path.length - 1)
          : path;
        if (!state.customer) {
          // non-login case
          switch (pathWithoutEndingSlash) {
            case '':
            case '/sign-in':
            case '/sign-up':
            case '/forgot-password':
            case '/update-password':
            case '/update-password/success':
            case '/forgot-password/instruction-sent':
            case '/terms-and-conditions':
            case '/privacy':
            case '/access-denied/login-required':
              break;
            default:
              await navigate('/access-denied/login-required', {
                replace: true,
              });
          }
        } else {
          // login case
          switch (state.customer?.metadata?.status) {
            case 'approved':
              break;
            case 'rejected':
            case 'pending':
            default:
              switch (pathWithoutEndingSlash) {
                case '':
                case '/sign-up-pending':
                case '/terms-and-conditions':
                case '/privacy':
                case '/access-denied/approval-required':
                  break;
                default:
                  await navigate('/access-denied/approval-required', {
                    replace: true,
                  });
              }
          }
        }
        setIsAccessChecked(true);
      };
      checkAccess();
    }
  }, [initCustomer, state.customer, location.pathname]);

  return (
    <CustomerContext.Provider
      {...props}
      value={{
        ...state,
        loading,
        actions: {
          getCompany,
          logoutCustomer,
          requestResetPassword,
          resetPassword,
          createCustomer,
          loginCustomer,
          updateCustomerDetails,
          retrieveOrders,
        },
      }}
    >
      {isAccessChecked && props.children}
    </CustomerContext.Provider>
  );
};
