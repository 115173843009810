import { notification } from 'antd';
import React, { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import CheckCircle from '../../icons/check-circle';
import CloseCircle from '../../icons/close-circle';

type NotificationType = 'success' | 'loading' | 'error';

export const closeNotification = (key: string) => {
  notification.close(key);
};

export default ({
  key,
  type,
  message,
}: {
  key: string;
  type: NotificationType;
  message: ReactNode;
}) => {
  let icon: ReactNode;
  switch (type) {
    case 'success':
      icon = <CheckCircle />;
      break;
    case 'loading':
      icon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
      break;
    default:
      icon = <CloseCircle />;
  }
  notification.open({
    key: key,
    closeIcon: <></>,
    message: (
      <div className="flex">
        <div
          className={`!text-primary-6 ${type === 'loading' ? '' : 'mt-1'} mr-2`}
        >
          {icon}
        </div>
        {message}
      </div>
    ),
  });
};
