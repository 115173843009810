import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_MEDUSA_BACKEND_URL || 'http://localhost:9000',
  headers: {
    Autorization: `Bearer ${process.env.GATSBY_MEILISEARCH_MASTER_KEY}`,
  },
});

const searchProducts = (filter) => {
  return axiosInstance.post('/store/products/search', filter);
};

export { searchProducts };
