import axios from 'axios';
import React, { createContext } from 'react';
import { useMedusa } from '../hooks/use-medusa';
import { Order } from '../types/Order';

const PAGE_SIZE = 24;
const defaultOrderContext = {
  pageSize: PAGE_SIZE,
  actions: {
    listOrders: async (offset?: number): Promise<OrderResponse> => ({
      orders: [],
      count: 0,
      offset: 0,
      limit: 24,
    }),
    getOrder: async (displayId: string) => undefined,
  },
};

interface OrderResponse {
  orders: Order[];
  count: number;
  offset: number;
  limit: number;
}

const OrderContext = createContext(defaultOrderContext);

export default OrderContext;

export const OrderProvider = (props) => {
  const client = useMedusa();

  const listOrders = async (
    offset: number = 0,
    limit: number = PAGE_SIZE
  ): Promise<OrderResponse> => {
    const response = await axios.get<OrderResponse>('/store/orders', {
      params: {
        offset,
        limit,
      },
    });
    return response.data;
  };

  const getOrder = async (displayId) => {
    const response = await axios.get<Order>(`/store/orders/${displayId}`);
    return response.data;
  };

  return (
    <OrderContext.Provider
      {...props}
      value={{
        pageSize: PAGE_SIZE,
        actions: {
          listOrders,
          getOrder,
        },
      }}
    />
  );
};
