import React from 'react';
const CloseCircle = ({ color = '#FF4D4F' }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 1.33325C11.6868 1.33325 14.6668 4.31325 14.6668 7.99992C14.6668 11.6866 11.6868 14.6666 8.00016 14.6666C4.3135 14.6666 1.3335 11.6866 1.3335 7.99992C1.3335 4.31325 4.3135 1.33325 8.00016 1.33325ZM10.3935 4.66658L8.00016 7.05992L5.60683 4.66658L4.66683 5.60659L7.06016 7.99992L4.66683 10.3933L5.60683 11.3333L8.00016 8.93992L10.3935 11.3333L11.3335 10.3933L8.94016 7.99992L11.3335 5.60659L10.3935 4.66658Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseCircle;
