import Medusa from '@medusajs/medusa-js';
import React, { createContext, useEffect } from 'react';
import { CartProvider } from './cart-context';
import { CollectionProvider } from './collection-context';
import { CustomerProvider } from './customer-context';
import { OrderProvider } from './order-context';
import { ProductProvider } from './product-context';
import { RegionProvider } from './region-context';
import { StoreProvider } from './store-context';

const defaultMedusaContext = {
  /**
   * @type {Medusa}
   */
  client: null,
};

const MedusaContext = createContext(defaultMedusaContext);
export default MedusaContext;

export const MedusaProvider = ({ children, client }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      function appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
      }

      appHeight();
      // We listen to the resize event
      window.addEventListener('resize', appHeight);
      return () => {
        window.removeEventListener('resize', appHeight);
      };
    }
  }, []);
  return (
    <MedusaContext.Provider value={{ client }}>
      <StoreProvider>
        <CollectionProvider>
          <ProductProvider>
            <CustomerProvider>
              <OrderProvider>
                <RegionProvider>
                  <CartProvider>{children}</CartProvider>
                </RegionProvider>
              </OrderProvider>
            </CustomerProvider>
          </ProductProvider>
        </CollectionProvider>
      </StoreProvider>
    </MedusaContext.Provider>
  );
};
