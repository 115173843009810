import Medusa from '@medusajs/medusa-js';
import React from 'react';
import './src/styles/theme.css';
import './src/styles/App.less';
import { MedusaProvider } from './src/context/medusa-context';
import axios from 'axios';
import { Settings } from 'luxon';
import { LayoutProvider } from './src/context/layout-context';

const BASE_URL =
  process.env.GATSBY_MEDUSA_BACKEND_URL || 'http://localhost:9000';

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const medusaClient = new Medusa({
  maxRetries: 2,
  baseUrl: BASE_URL,
});

Settings.defaultLocale = 'th';

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Noto+Sans+Thai:300,400,500,600,700|Inter:300,400,500,600,700"
      ></link>
      <MedusaProvider client={medusaClient}>
        <LayoutProvider>{element}</LayoutProvider>
      </MedusaProvider>
    </>
  );
};
