import React from 'react';
const CheckCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06659 9.2L5.61659 7.75C5.49436 7.62778 5.34436 7.56667 5.16658 7.56667C4.98881 7.56667 4.83325 7.63334 4.69992 7.76667C4.5777 7.88889 4.51659 8.04445 4.51659 8.23334C4.51659 8.42222 4.5777 8.57778 4.69992 8.7L6.59992 10.6C6.72214 10.7222 6.8777 10.7833 7.06659 10.7833C7.25547 10.7833 7.41103 10.7222 7.53325 10.6L11.3166 6.81667C11.4388 6.69445 11.4999 6.54445 11.4999 6.36667C11.4999 6.18889 11.4333 6.03334 11.2999 5.9C11.1777 5.77778 11.0221 5.71667 10.8333 5.71667C10.6444 5.71667 10.4888 5.77778 10.3666 5.9L7.06659 9.2ZM7.99992 14.6667C7.0777 14.6667 6.21103 14.4916 5.39992 14.1413C4.58881 13.7916 3.88325 13.3167 3.28325 12.7167C2.68325 12.1167 2.20836 11.4111 1.85859 10.6C1.50836 9.78889 1.33325 8.92222 1.33325 8C1.33325 7.07778 1.50836 6.21111 1.85859 5.4C2.20836 4.58889 2.68325 3.88334 3.28325 3.28334C3.88325 2.68334 4.58881 2.20822 5.39992 1.858C6.21103 1.50822 7.0777 1.33334 7.99992 1.33334C8.92214 1.33334 9.78881 1.50822 10.5999 1.858C11.411 2.20822 12.1166 2.68334 12.7166 3.28334C13.3166 3.88334 13.7915 4.58889 14.1413 5.4C14.4915 6.21111 14.6666 7.07778 14.6666 8C14.6666 8.92222 14.4915 9.78889 14.1413 10.6C13.7915 11.4111 13.3166 12.1167 12.7166 12.7167C12.1166 13.3167 11.411 13.7916 10.5999 14.1413C9.78881 14.4916 8.92214 14.6667 7.99992 14.6667Z"
        fill="url(#paint0_linear_1016_125)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1016_125"
          x1="1.33325"
          y1="1.33334"
          x2="17.6665"
          y2="7.4488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#73D13D" />
          <stop offset="1" stop-color="#52C41A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckCircle;
