import React, { createContext, createRef } from 'react';

const defaultLayoutContext = {
  mainContentRef: { current: null },
};

const LayoutContext = createContext<{
  mainContentRef: React.RefObject<HTMLDivElement>;
}>(defaultLayoutContext);

export default LayoutContext;

const mainContentRef = createRef<HTMLDivElement>();

export const LayoutProvider = (props) => {
  return (
    <LayoutContext.Provider
      {...props}
      value={{
        mainContentRef,
      }}
    />
  );
};
