import axios from 'axios';
import React, { createContext, useEffect, useReducer, useState } from 'react';

type StoreDetail = {
  title: string;
  subtitle: string;
};
const defaultStoreContext = {
  store: undefined,
  loading: false,
};

const StoreContext = createContext<{
  store: StoreDetail | undefined;
  loading: boolean;
}>(defaultStoreContext);
export default StoreContext;

const ACTIONS = {
  LIST_STORE: 'LIST_STORE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LIST_STORE:
      return {
        ...state,
        store: action.payload,
      };
    default:
      break;
  }
};

export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultStoreContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!state.store) {
      getStore();
    }
  }, []);

  const getStore = async () => {
    setLoading(true);
    const response = await axios.get<StoreDetail>('/store/store');
    setLoading(false);
    dispatch({ type: ACTIONS.LIST_STORE, payload: response.data });
    return response.data;
  };

  return (
    <StoreContext.Provider
      {...props}
      value={{
        ...state,
        loading,
        actions: {
          getStore,
        },
      }}
    />
  );
};
