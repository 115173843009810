import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useMedusa } from '../hooks/use-medusa';
import { Collection, HierarchyCollection } from '../types/Collection';
import { filterParentCollections } from '../utils/collectionUtil';

const defaultCollectionContext = {
  collections: undefined,
  loading: false,
  hierarchyCollections: [],
};

const CollectionContext = createContext<{
  collections: Collection[] | undefined;
  loading: boolean;
  hierarchyCollections: HierarchyCollection[];
}>(defaultCollectionContext);
export default CollectionContext;

const ACTIONS = {
  LIST_COLLECTION: 'LIST_COLLECTION',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LIST_COLLECTION:
      return {
        ...state,
        collections: action.payload,
      };
    default:
      break;
  }
};

export const CollectionProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultCollectionContext);
  const [loading, setLoading] = useState(true);
  const client = useMedusa();

  useEffect(() => {
    if (!state.collection) {
      listCollection();
    }
  }, []);

  const listCollection = async () => {
    setLoading(true);
    const response = await client.collections.list({
      limit: 10000,
    });
    setLoading(false);
    dispatch({ type: ACTIONS.LIST_COLLECTION, payload: response.collections });
    return response.collections;
  };

  const hierarchyCollections = filterParentCollections(
    state.collections || []
  ).map((parent) => ({
    ...parent,
    children:
      state.collections
        ?.filter((collection) =>
          parent.metadata?.children
            ?.replace(/ /g, '')
            ?.split(',')
            ?.includes(collection.handle)
        )
        ?.sort((a, b) => a.title.localeCompare(b.title)) || [],
  }));

  return (
    <CollectionContext.Provider
      {...props}
      value={{
        ...state,
        loading,
        hierarchyCollections,
        actions: {
          listCollection,
        },
      }}
    />
  );
};
